.survey{
	background:#fff url()  center / cover fixed;
	width:100%;
	position:relative;

	@media #{$bp-800} {
		height:100vh;
	}

	&.released{
		height:auto;
		min-height:100vh;

		.center-wrapper, .quiz-box{
			height:auto !important;
			height:100vh;
			overflow-y: auto;
			&.final {
				min-height: 100vh !important;
			}
		}
	}
}

.landing-page{
	background:#fff;
	width:100%;
	height:120vh;

	@media #{$bp-800} {
		height:100vh;
	}

	&:after{
		content:"Loading...";
		display:block;
		font-size:$fs-xl;
		width:4rem;
		height:2rem;
		text-align:center;
		position:absolute;
		z-index:0;
		top:50%;
		left:50%;
		transform:translateX(-50%) translateY(-50%);
	}

	&.loaded{
		&:after{
			display:none;
		}
	}

	.center-wrapper{
		position:relative;
		z-index:2;
		opacity:0;
		transition:.5s;

		&.ready{
			opacity:1;		
		}

		#home-logo{
			width:12rem;
			height:8rem;
			display:block;
			margin:0 auto;
			background:url(../images/Thrive-logo-white.svg) no-repeat center center;
			background-size:contain;

			@media #{$bp-800} {
				width:20rem;
				height:14rem;
				margin:0 auto 1rem;
			}
		}

		#home-title{
			font-size:$fs-xxl;
			color:#fff;
			text-transform:uppercase;
			font-family:$ff-base;
			margin:0 auto;
			line-height:1.2em;
			padding:0;
			max-width:20rem;

			@media #{$bp-800} {
				max-width:100%;
				font-size:$fs-xxxl;
			}
		}

		#home-copy{
			font-size:$fs-xl;
			color:#fff;
			padding:0 1rem;

			@media #{$bp-800} {
				padding:0;
			}
		}

		#start-quiz{
			font-size:$fs-xl;
			color:#fff;
			border:2px solid #fff;
			padding:1rem;
			margin:1rem 0;
			text-transform:uppercase;
			background:rgba(0,0,0,.55);
			display:inline-block;
			transition:.2s;

			&:hover, &:active{
				background:#fff;
				color:#000;
			}

			@media #{$bp-800} {
				margin:3rem 0;
			}
		}

		#home-disclaimer{
			font-size:$fs-lg;
			color:#fff;
			max-width:60rem;
			margin:0 auto 5rem;
			padding:0 2rem;

			@media #{$bp-800} {
				padding:0;
			}
		}
	}

	.home-shadow{
		position:absolute;
		z-index:1;
		width:100%;
		height:100%;
		top:0;
		left:0;
		background:rgba(0,0,0,.5);
		display:block;
	}

	#home-slideshow{
		position:absolute;
		top:0;
		left:0;
		width:100%;
		height:120vh;
		z-index:0;
		opacity:0;
		transition:.5s;

		@media #{$bp-800} {
			height:100vh;

			&:after{
				height:100vh;
			}
		}

		&.active{
			opacity:1;
		}

		.slide-image{
			width:100%;
			height:120vh;
			opacity:0;
			transition:opacity 3s;
			position:absolute;
			top:0;
			left:0;
			z-index:0;

			@media #{$bp-800} {
				height:100vh;
			}

			&.active{
				opacity:1;
			}

			&.prepped{
				z-index:2;
			}
		}
	}
}

.center-wrapper{
	width:100%;
	min-height:calc(100vh - 4rem);
	display:table;

	@media #{$bp-600} {
		height:100vh;
	}

	.center-block{
		display:table-cell;
		vertical-align:middle;

		.temp-holder{
			text-align:center;
		}

		.quiz-box{
			@extend .clearfix;

			margin:0 auto;
			width:0;
			border:2px solid #fff;
			position:relative;
			overflow:hidden;
			opacity:0;
			transition:.5s;

			&.open{
				opacity:1;
				width:22rem;
				max-width: 100vw;

			    @media #{$bp-600} {
			    	width: 30rem;
			    }

			    @media #{$bp-1000} {
			    	width: 50rem;
			    }

			    &.final{
				    width: 100%;
				    height: 100vh;
				    border: 0;
				    padding-top: 6rem;
				    text-align: center;		

				    @media #{$bp-600} {
				    	padding-top:8rem;
				    }

				    @media #{$bp-1000} {
				    	padding-top: 9rem;
				    }	  

				    #quiz-text{
				    	width:100%;	
				    	padding:2rem 2rem 0;

					    @media #{$bp-600} {
					    	
					    }

					    @media #{$bp-1000} {
					    	max-width:70rem;
					    }	 

					    h1{
					    	text-transform:uppercase;
					    	margin-top:0;
					    } 

					    h2{
					    	font-size:24px;
					    	line-height:1.2em;
					    }

					    h3{
					    	font-size:24px;
					    	line-height:1.2em;
					    }

					    #quiz-signup{
					    	@extend .clearfix;
					    	clear:both;
					    	position:relative;
					    	margin-bottom:2rem;

					    	#signup-email{
					    		width:100%;
					    		background:transparent;
					    		color:#fff;
					    		border:2px solid #fff;
					    		padding:1.35rem;
					    		margin-right:2rem;
					    		font-size:20px;
					    		margin-bottom:1rem;

					    		@media #{$bp-600} {
						    		width:calc(100% - 18rem);
						    		float:left;
						    		margin-bottom:0;
					    		}
					    	}

					    	#signup-button{
					    		width:100%;
					    		padding:1.15rem;
					    		text-transform:uppercase;
					    		background:$c-blue;
					    		border:0;
					    		color:#fff;
					    		font-size:28px;
					    		font-family:$ff-base;
					    		transition:.2s;
					    		display: block;
					    		&:hover, &:active{
					    			background:#fff;
					    			color:#000;
					    		}

					    		@media #{$bp-600} {
						    		width:16rem;
						    		float:left;
					    		}
					    	}
					    }

					    .share-wrapper{
					    	width:100%;
					    	text-align:center;

					    	h3{
					    		text-transform:uppercase;
					    		margin-bottom:0;
					    	}

					    	.social-icon{
					    		display:inline-block;
					    		float:none;

								&.twitter{
									background:url(../images/twitter-icon-white.svg) no-repeat center center;
									background-size:contain;
								}

								&.facebook{
									background:url(../images/facebook-icon-white.svg) no-repeat center center;
									background-size:contain;
								}

								&.mail{
									background:url(../images/mail-white.svg) no-repeat center center;
									background-size:contain;
								}
					    	}
					    }

					    .sitelink{
					    	margin-bottom:4rem;
					    }
				    } 
			    }
			}


			&:before{
				content:"";
				background:rgba(0,0,0,.8) url(../images/Enhancer-2.jpg)  center / cover fixed;
				margin: -35px;
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				filter: blur(5px) brightness(0.5);
				z-index: 0;
			}

			&:after{
				content:"";
				display:block;
				position:absolute;
				top:0;
				left:0;
				background:rgba(0,0,0,.25);
				z-index:1;
				width:100%;
				height:100%;
				visibility:visible;
			}

			#quiz-text, p, span{
				position:relative;
				z-index:3;
			}

			#quiz-text{
				color:#fff;
				padding:0 2rem;
				font-size:.8rem;
				line-height:1.6em;
				font-weight:200;    
				left: 50%;
			    width: 22rem;
			    transform: translateX(-50%);

			    @media #{$bp-600} {
					font-size:1.4rem;
					line-height:1.8em;
			    	width: 30rem;
			    }

			    @media #{$bp-1000} {
			    	width: 50rem;
			    }

			    &.final{
			    	text-align:center;
			    	padding:2rem 0 0;
			    }
			}

			#share-quiz{
				display:block;
				padding:1rem 4rem;
				border:2px solid #fff;
				text-align:center;
				max-width:24rem;
				text-transform:uppercase;
				margin:2rem auto;
				cursor:pointer;
				background:rgba(0,0,0,.55);
				transition:.2s;

				&:hover, &:active{
					background:#fff;
					color:#000;
				}
			}

			#zip-buttons{
				border-top:2px solid #fff;

				button{
					padding:1rem;
					text-align:center;
					border:0;
					background:transparent;
					width:50%;
					float:left;
					color:#fff;
					text-transform:uppercase;
					font-size:1.2rem;
					font-weight:800;
					transition:.2s;

					@media #{$bp-600} {
						font-size:2rem;
					}

					&:hover, &:active{
						background:#fff;
						color:#000;
					}

					&#zip-skip{
						font-weight:200;
						border-right:1px solid #fff;

						&:after{
							content:"";
							display:inline-block;
							width:.75rem;
							height:.75rem;
							border-top:2px solid #fff;
							border-right:2px solid #fff;
							transform:rotate(45deg);
							transform-origin:100% 100%;
							margin:.2rem 0 0 .5rem;
							transition:.2s;
						}

						&:hover, &:active{
							&:after{
								border-color:#000;
							}
						}
					}

					&#zip-submit{
						border-left:1px solid #fff;
					}
				}
			}

			#zip{
				display:block;
				padding:1rem;
				border:2px solid #fff;
				margin:2rem auto;
				max-width:18rem;
				background:transparent;
				font-size:20px;
				color:#fff;

			}

			#zip-message, #signup-message{
				display:none;
				color:$c-red;
				padding:1rem 2rem;
				background:#fff;
				margin-bottom:1rem;
			}

			.answer-wrapper{
				position:relative;
				height:3.8rem;
				left: 50%;
			    width: 22rem;
			    transform: translateX(-50%);
			    z-index:3;
			    bottom:-5rem;
			    transition:.5s;

			    @media #{$bp-600} {
			    	width: 30rem;
			    }

			    @media #{$bp-1000} {
			    	width: 50rem;
			    }

			    &.open{
			    	bottom:0;
			    }
			}

			.quiz-next{
				display:none;
				clear:both;
				font-weight:800;
				padding:1rem;
				font-size:1.2rem;
				z-index:3;
				position:relative;
				width:100%;
				color:#fff;
				text-align:center;
				cursor:pointer;
				margin-top:7.5rem;
				transition:.2s;

				@media #{$bp-600} {
					margin-top:10.2rem;
					font-size:2rem;
				}

				span{
					position:relative;
					padding-right:1rem;

					@media #{$bp-600} {
						padding-right:2rem;
					}

					&:after{
						content:"";
						border-top:2px solid #fff;
						border-right:2px solid #fff;
						width:.5rem;
						height:.5rem;
						transform:rotate(45deg);
						display:block;
						position:absolute;
						top:.5rem;
						right:0;
						transition:.2s;

						@media #{$bp-600} {
							border-top:4px solid #fff;
							border-right:4px solid #fff;
							width:.75rem;
							height:.75rem;
							top:.8rem;
						}
					}
				}

				&:hover, &:active{
					color:#000;
					background:#fff;

					span{
						&:after{
							border-color:#000;
						}
					}
				}
			}

			.quiz-answer{
				display:block;
				width:50%;
				float:left;
				padding:1rem;
				font-size:1.5rem;
				text-align:center;
				color:#fff;
				border-top:2px solid #fff;
				cursor:pointer;
				text-transform:uppercase;
				font-weight:800;
				transition:.2s;

			    @media #{$bp-600} {
			    	font-size:2rem;
			    }

				&.yes{
					border-right:2px solid #fff;
				}

				&:hover, &:active{
					background:#fff;
					color:#000;
				}
			}
		}



		.quiz-wrapper{
			position:relative;
			transition:.5s;

			#post-answer{
				position:absolute;
				top:0;
				z-index:3;
				border:2px solid #fff;
				width:100%;
				display:none;
				overflow:hidden;

				&:before{
					content:"";
					background:rgba(0,0,0,.8) url(../images/Enhancer-2.jpg) center / cover fixed;
					margin: -35px;
					position: absolute;
					top: 0;
					right: 0;
					bottom: 0;
					left: 0;
					filter: blur(5px) brightness(0.5);
					z-index: 0;
				}	

				&:after{
					content:"";
					display:block;
					position:absolute;
					top:0;
					left:0;
					background:rgba(0,0,0,.25);
					z-index:1;
					width:100%;
					height:100%;
					visibility:visible;
				}

				.post-yes{
					width:65%;
					text-align:center;
					float:left;
					font-size:.8rem;
					line-height:1.2em;
					font-weight:800;
					padding:1rem;
					position:relative;
					height:5rem;
					display:table;
					z-index:2;

					@media #{$bp-800} {
						width:75%;
						font-size:1.4rem;
						line-height:1.5em;
						padding:1.5rem 1rem;
						min-height:auto;
						min-height:9rem;
					}

					span{
						display:table-cell;
						vertical-align:middle;
						padding:0;
						margin:0 auto;
					}
				}

				.post-no{
					width:35%;
					text-align:center;
					float:left;
					font-size:.8rem;
					line-height:1.2em;
					font-weight:800;
					padding:1rem;
					position:relative;
					height:5.7rem;
					display:table;
					z-index:2;

					@media #{$bp-800} {
						width:25%;
						font-size:1.4rem;
						line-height:1.5em;
						padding:1.5rem 1rem;
						min-height:auto;
						min-height:9rem;

						&.narrow {
							padding: 2.75rem 1rem 2rem;
							min-height:9rem;

							span{
								font-size: 1rem;
								line-height: 1.6rem;
							}
						}
					}

					span{
						display:table-cell;
						vertical-align:middle;
						margin:0 auto;
						padding:0;
					}
				}

				&.yes{
					.post-yes{
						background:#fff;
						color:$c-darkergrey;
					}

					.post-no{
						color:#fff;
					}
				}

				&.no{
					.post-yes{
						color:#fff;
					}

					.post-no{
						background:#fff;
						color:$c-darkergrey;
					}
				}
			}
		}
	}
}

#finalize{
	display:none;
}

#fader{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:url(../images/Enhancer-2.jpg)  center / cover fixed;
	z-index:0;
	display:none;

	&.final{
		&:after{
			content:"Getting Results...";
			display:block;
			width:6rem;
			height:1rem;
			text-align:center;
			position:absolute;
			top:50%;
			left:50%;
			font-size:$fs-xl;
			white-space: nowrap;
			transform:translateX(-50%) translateY(-50%);
		}
	}
}
#gif-container{
	position:absolute;
	top:0;
	left:0;
	width:100%;
	height:100%;
	background:url(../images/Enhancer-2.gif)  center / cover fixed;
	z-index:0;
	display:none;
}

#modal{
	position:fixed;
	top:0;
	left:0;
	width:100%;
	height:100vh;
	z-index:9988;
	background:rgba(0,0,0,.8);
	display:none;

	.modal-content{
		padding:4rem 2rem;
		background:#fff;
		color:$c-darkergrey;
		width:80%;
		height:80vh;
		margin:10vh 10%;
		display:none;
		position:relative;
		overflow:auto;

		@media #{$bp-800} {
			padding:4rem;
		}

		&#question-modal{
		    max-width: 56em;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    margin: 0;
		    transform: translateX(-50%) translateY(-50%);
		}

		&#share-modal{

		    max-width: 28rem;
		    max-height: 28rem;
		    position: absolute;
		    top: 50%;
		    left: 50%;
		    margin: 0;
		    transform: translateX(-50%) translateY(-50%);

			h2{
				text-align:center;
				margin-top:10%;
			}

			.modal-copy{
				text-align:center;
			}

			.share-wrapper{
				width:18.5rem;
				position:relative;
				margin:0 auto;
			}
		}

		h2{
			font-size:24px;
			text-transform:uppercase;

		}

		.modal-copy{
			font-size:1rem;
		}

		.modal-close{
			color:$c-blue;
			position:absolute;
			top:2rem;
			right:2rem;
			cursor:pointer;
			text-transform:uppercase;
			font-weight:800;

			&:after{
				content:"";
				display:block;
				float:right;
				width:2rem;
				height:2rem;
				background:url(../images/close-icon.svg) no-repeat center center;
				background-size:contain;
				margin-top:-.1rem;
				margin-left:.5rem;
			}
		}
	}
}

.social-icon{
	display:block;
	float:left;
	margin:2rem 1rem;
	position:relative;
	width:4rem;
	height:4rem;
	text-indent:-1000px;
	overflow:hidden;

	&.twitter{
		background:url(../images/twitter-icon.svg) no-repeat center center;
		background-size:contain;
	}

	&.facebook{
		background:url(../images/facebook-icon.svg) no-repeat center center;
		background-size:contain;
	}

	&.mail{
		background:url(../images/mail.svg) no-repeat center center;
		background-size:contain;
	}
}

#question-form{
	fieldset{
		border:0;
		padding:0;
	}

	.form-row{
		width:100%;
		clear:both;
		margin-bottom:2rem;

		label{
			display:none;
		}

		textarea{
			width:100%;
			min-height:6rem;
			border:1px solid #000;
			padding:1rem;
			font-size:20px;
		}

		input[type="text"]{
			width:100%;
			padding:1rem;
			border:1px solid #000;
			font-size:20px;
		}

		::-webkit-input-placeholder { 
		  color:$c-darkgrey;
		}
		::-moz-placeholder {
		  color:$c-darkgrey;
		}
		:-ms-input-placeholder {
		  color:$c-darkgrey;
		}
		:-moz-placeholder { 
		  color:$c-darkgrey;
		}

		#question-submit{
			border:0;
			padding:1rem;
			text-align:center;
			width:100%;
			background:$c-blue;
			color:#fff;
			text-transform:uppercase;
			border:2px solid $c-blue;
			transition:.2s;

			@media #{$bp-600} {
				width:12rem;
				font-size:1rem;
			}

			&:hover, &:active{
				background:#fff;
				color:$c-blue;
			}
		}
	}
}

#question-response{
	font-size:1rem;
	border:2px solid $c-blue;
	padding:1rem;
	display:none;

	@media #{$bp-600} {
		padding:2rem;
	}

	&.error{
		border-color:$c-red;
		color:$c-red;
	}
}

#add-another{
	cursor:pointer;
	border:0;
	padding:1rem;
	text-align:center;
	width:100%;
	background:$c-blue;
	color:#fff;
	text-transform:uppercase;
	border:2px solid $c-blue;
	display:none;
	margin-top:2rem;
	transition:.2s;

	@media #{$bp-600} {
		font-size:1rem;
		width:20rem;
	}

	&:hover, &:active{
		background:#fff;
		color:$c-blue;
	}
}

//FINAL SCREEN
.sitelink {
	a {
		color: $c-blue;
		transition: all .25s;
		&:hover, &:focus {
			color: $c-orange;
		}
	}
}

#user-id{
	position:fixed;
	top:8rem;
	right:0;
	background:rgba(0,0,0,.8);
	color:#fff;
	transform:rotate(-90deg);
	transform-origin:100% 100%;
	padding:1rem;
	font-size:1.2rem;
	display:none;
}

//THANK YOU TEXT

.thank-you {
	font-size: 2.5rem;
    display: block;
    position: relative;
    clear: both;
    float: left;
    margin: 0 50%;
    white-space: nowrap;
    transform: translateX(-50%);
}