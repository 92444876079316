/*==========================
COLORS
==========================*/
$c-grey: rgba(0,0,0,.25);
$c-darkgrey: rgba(0,0,0,.55);
$c-darkergrey:#454545;
$c-green: #5DB146;
$c-blue: #00AEEF;
$c-orange: #F7941E;
$c-red: #ff0000;
$c-yellow: #F7941E;


/*==========================
FONTS
==========================*/
$ff-header: usual, sans-serif;
$ff-base: 'Helvetica', 'Arial', sans-serif;

/*==========================
FONT SIZES
==========================*/
$fs-xxsmall: .75em;
$fs-xsmall: 1em;
$fs-small: 1.4em;
$fs-med: 1.5em;
$fs-lg: 1.9em;
$fs-xl: 2.25em;
$fs-xxl: 3.5em;
$fs-xxxl: 6em;

/*==========================
MEDIA QUERIES
==========================*/
$bp-400 : "only screen and (min-width:400px)";
$bp-500 : "only screen and (min-width:500px)";
$bp-600 : "only screen and (min-width:600px)";
$bp-700 : "only screen and (min-width:700px)";
$bp-783 : "only screen and (min-width:783px)";
$bp-800 : "only screen and (min-width:800px)";
$bp-900 : "only screen and (min-width:900px)";
$bp-1000 : "only screen and (min-width:1000px)";
$bp-1050 : "only screen and (min-width:1050px)";
$bp-1100 : "only screen and (min-width:1100px)";
$bp-1150 : "only screen and (min-width:1150px)";
$bp-1200 : "only screen and (min-width:1200px)";
$bp-1300 : "only screen and (min-width:1300px)";
$bp-1350 : "only screen and (min-width:1350px)";
$bp-1400 : "only screen and (min-width:1400px)";
$bp-1500 : "only screen and (min-width:1500px)";
$bp-1600 : "only screen and (min-width:1600px)";
$bp-1700 : "only screen and (min-width:1700px)";
$bp-1800 : "only screen and (min-width:1800px)";
$bp-1900 : "only screen and (min-width:1900px)";
