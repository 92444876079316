body {
  margin: 0;
  padding: 0;
  font-family: $ff-base;
  color: #000;
  font-size: 10px;
  width: 100vw;
  overflow-x: hidden;
  font-weight: 300;
  line-height: 1.8rem;
  background:#000;

  * {
  	box-sizing: border-box;
  	backface-visibility: hidden;
  }
}

a {
	text-decoration: none;
}

h1, h2, h3, h4, h5, h6{
	font-family:$ff-header;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

button {
	cursor: pointer;
	outline:none;
}

strong{
	&.blue{
		color:$c-blue;
	}
}

::-webkit-input-placeholder { 
  color:#fff;
}
::-moz-placeholder {
  color:#fff;
}
:-ms-input-placeholder {
  color:#fff;
}
:-moz-placeholder { 
  color:#fff;
}

.container{
	max-width:65rem;
	margin:0 auto;
	padding:0 1rem;
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0;
}
.clearfix { display: inline-block; }
/* start commented backslash hack \*/
* html .clearfix { height: 1%; }
.clearfix { display: block; }
/* close commented backslash hack */

/*temporary measures */
.hackSwapButton2{
	display:none;
}