#quiz-footer{
	@extend .clearfix;
	clear:both;
	position:absolute;
	z-index:100;
	bottom:0;
	left:0;
	width:100%;
	height:4rem;
	text-align:center;
	opacity:1;
	transition:.2s;

	@media #{$bp-600} {
		bottom:0;
	}

	&.hidden{
		opacity:0;
	}

	#accessibility-button{
		color:$c-yellow;
		font-size:.8rem;
		text-transform:uppercase;
		position:absolute;
		z-index:2;
		left:0;
		bottom:0;
		font-weight:800;

		.accessibility-text{
			display:block;
			position:absolute;
			bottom:.5rem;
			left:0rem;
			width:10rem;
			font-size:10px;
			line-height:1rem;
			cursor:pointer;

			@media #{$bp-600} {
				width:14rem;
				left:.5rem;
				font-size:14px;
				line-height:1.2rem;
				bottom:1rem;
			}
		}

		.accessibility-icon{
			width:1.5rem;
			height:1.5rem;
			display:block;
			background:url(../images/accessibility-icon.svg) no-repeat center center;
			background-size:contain;
			position:absolute;
			z-index:1;
			bottom:.5rem;
			left:.5rem;

			@media #{$bp-600} {
				bottom:1.2rem;
			}

			@media #{$bp-800} {
				width:2rem;
				height:2rem;
				left: 1rem;
			}
		}

		.hexagon{
			display:block;
			position:absolute;
			bottom:-.8rem;
			left:1rem;
			width:1.9rem;
			height:1rem;
			cursor:pointer;

			@media #{$bp-600} {
				left:2rem;
				bottom:-.2rem;
			}

			.icon{
				background:url(../images/accessibility.svg) no-repeat center center;
				background-size:contain;
				display:block;
				width:1rem;
				height:1rem;
				margin-left:-.21rem;
			}
		}

		&:before{
			content:"";
			display:block;
			width: 0; 
			height: 0; 
			border-right: 15rem solid transparent;
			border-left: 0px solid transparent;
			border-bottom: 5rem solid #fff;
			bottom:0;
			position:absolute;
			left:0;
			bottom:0;

			@media #{$bp-600} {
				border-right:38rem solid transparent;
				border-bottom: 6rem solid #fff;
			}
		}

		@media #{$bp-600} {
			font-size:13px;
		}
	}

	#quiz-counter{
		color:$c-blue;
		font-size:14px;
		text-transform:uppercase;
		position:absolute;
		z-index:1;
		bottom:0;
		right:0;
		font-weight:800;

		@media #{$bp-600} {
			font-size:1.2rem;
		}

		.counter-content{
			position:absolute;
			bottom:.5rem;
			right:.5rem;
			width:10rem;
			text-align: right;
			@media #{$bp-600} {
				bottom:1.5rem;
				width:14rem;
			}

			@media #{$bp-800} {
				bottom:1rem;
				right: 1rem;
			}
		}

		&:before{
			content:"";
			display:block;
			width: 0; 
			height: 0; 
			border-left: 15rem solid transparent;
			border-right: 0px solid transparent;
			border-bottom: 5rem solid #fff;
			bottom:0;
			position:absolute;
			right:0;
			bottom:0;

			@media #{$bp-600} {
				border-left:38rem solid transparent;
				border-bottom: 6rem solid #fff;
			}
		}

		@media #{$bp-600} {
			font-size:1.4rem;
		}
	}
}