#quiz-header {
	position:absolute;
	z-index:100;
	top:0;
	left:0;
	width:100%;

	&:before{
		content:"";
		display:block;
		width: 0; 
		height: 0; 
		border-left: 50vw solid transparent;
		border-right: 50vw solid transparent;
		border-top: 2.5rem solid #fff;
		position:relative;
		top:3rem;

		@media #{$bp-600} {
			border-top: 5rem solid #fff;
		}
	}

	&:after{
		content:"";
		display:block;
		width:100%;
		height:3rem;
		position:absolute;
		top:0;
		left:0;
		background:#fff;
	}

	.shadow{
		display:block;
		width: 0; 
		height: 0; 
		border-left: 50vw solid transparent;
		border-right: 50vw solid transparent;
		border-top: 2.5rem solid #000;
		position:relative;
		filter:blur(10px);
		position:absolute;
		z-index:-1;
		opacity:.5;
		top:3rem;

		@media #{$bp-600} {
			border-top: 5rem solid #000;
		}
	}

	.thrive-logo{
		width:7rem;
		height:2.5rem;
		background:url(../images/Thrive-logo-final@2x.png) no-repeat;
		background-size:contain;
		overflow:hidden;
		text-indent:-1000px;
		display:block;
		position:absolute;
		z-index:3;
		top:1rem;
		left:50%;
		transform:translateX(-50%);

		@media #{$bp-600} {
			width:10rem;
			height:4rem;
			top:2rem;
		}

		@media #{$bp-1000} {
			width:14rem;
			height:4rem;
			top:2rem;
		}
	}

	.suggest{
		position:absolute;
		top:.5rem;
		left:.5rem;
		color:$c-blue;
		z-index:3;
		text-transform:uppercase;
		font-size:.6rem;
		line-height:1.4em;
		font-weight:600;
		max-width:8rem;
		cursor:pointer;
		padding-top:.5rem;

		@media #{$bp-800} {
			left: 1rem;
			max-width:15rem;
			line-height:2.1em;
			font-size:1rem;
			font-weight:800;
			padding-top:0;
		}

		.question-icon{
			width:1.75rem;
			height:1.75rem;
			display:block;
			float:left;
			background:url(../images/question-icon.svg) no-repeat center center;
			background-size:contain;
			margin-right:.25rem;
			margin-top:-.3rem;

			@media #{$bp-800} {
				width: 2rem;
				height: 2rem;
				margin-right:1rem;
				margin-top:0;
			}
		}
	}

	.share{
		position:absolute;
		top:.5rem;
		right:.5rem;
		text-align:right;
		color:$c-green;
		z-index:3;
		text-transform:uppercase;
		font-size:.8rem;
		line-height:1.4em;
		font-weight:600;
		max-width:8rem;
		cursor:pointer;
		@media #{$bp-800} {
			right: 1rem;
		}

		.share-text{
			display:block;
			float:left;
			max-width:3rem;
			margin-top:.25rem;
			font-size:.6rem;
			line-height: .75rem;
			@media #{$bp-800} {	
				margin-top:0;
				font-size:1rem;
				max-width:5rem;
				margin-top:.5rem;
				line-height: 1rem;
			}

			.share-quiz{
				display:none;
			}
		}

		.share-icon{
			width:1.75rem;
			height:1.75rem;
			display:block;
			float:right;
			margin-left:.25rem;
			background:url(../images/share-icon.svg) no-repeat center center;
			background-size:contain;
			margin-right:0;
			margin-top:0;

			@media #{$bp-800} {	
				width:2rem;
				height:2rem;
				margin-left:1rem;
			}
		}

		@media #{$bp-800} {			
			max-width:15rem;
			line-height:2.1em;
			font-size:1rem;
			font-weight:800;

			.share-text{
				max-width:15rem;

				.share-quiz{
					display:inline;
				}
			}
		}
	}
}
